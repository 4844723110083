<template>
  <BodySimple :loading="firstLoading || loadingGroup">
    <template #header>
      <h2 class="text-xl font-bold">
        <img
          src="@/assets/images/1054990_rocket_spacecraft_spaceship_icon.png"
          class="h-20 ml-2 inline-block"
        />
        سفارش تبلیغات هدفمند در گروه ها
      </h2>
      <ul class="list-disc list-inside mt-3">
        <li class="text-red-500">
          ثبت سفارش تبلیغات به معنی قبول شرایط و
          <router-link to="/" class="text-sky-600">قوانین سفارش</router-link>
          می‌باشد
        </li>
        <li>
          برای خواندن شرایت و نحوه تبلیغات
          <router-link to="/" class="text-sky-600">اینجا کلیک کنید</router-link>
        </li>
        <li>بعد از تکمیل سفارش و پرداخت میتوانید بنر و کلید ها را مشخص کنید</li>
        <li>حد اقل سفارش 10 هزار تومان می‌باشد</li>
      </ul>
    </template>
    <template #default>
      <template v-if="group?.id">
        <div
          v-if="group.info.ads"
          class="p-5 mb-5 bg-blueGray-100 border border-blueGray-200 rounded-md"
        >
          شما در حال سفارش تبلیغ در گروه {{ group?.name }} هستید
          <p v-if="group.noAd?.length">
            این گروه تبلیغاتی با موضوعات
            <span class="font-bold text-red-600">
              {{ L_.join(L_.map(group.noAd, "type.name"), "، ") }}
            </span>
            نمی‌پذیرد اگر تبلیغ شما در مورد این موضوعات است در این گروه سفارشی ثبت نکنید
          </p>
          <p>
            می‌توانید برای سفارش تبلیغات هدفمند در سایر گروه ها
            <router-link
              class="font-bold text-sky-500"
              @click="group = {}"
              :to="{
                name: 'TargetOrder',
                params: { id: $route.params.id },
              }"
            >
              اینجا کلیک کنید
            </router-link>
          </p>
          <p>
            می‌توانید برای سفارش تبلیغات سراسری در همه گروه ها
            <router-link
              class="font-bold text-sky-500"
              :to="{
                name: 'GroupOrder',
                params: { id: $route.params.id },
              }"
            >
              اینجا کلیک کنید
            </router-link>
          </p>
        </div>
        <div
          v-else
          class="p-5 mb-5 bg-blueGray-100 border border-blueGray-200 rounded-md"
        >
          <p>
            گروه {{ group?.name }} سفارش تبلیغات هدفمند را فعال نکرده. میتوانید از ادمین
            این گروه درخواست کنید آن را فعال کند.
          </p>
          <p>
            برای سفارش تبلیغات هدفمند در سایر گروه ها
            <outer-link
              class="font-bold text-sky-500"
              @click="group = {}"
              :to="{
                name: 'TargetOrder',
                params: { id: $route.params.id },
              }"
            >
              اینجا کلیک کنید
            </outer-link>
          </p>
          <p>
            برای سفارش تبلیغات سراسری در همه گروه ها
            <router-link
              class="font-bold text-sky-500"
              :to="{
                name: 'GroupOrder',
                params: { id: $route.params.id },
              }"
            >
              اینجا کلیک کنید
            </router-link>
          </p>
        </div>
      </template>
      <div
        class="w-full md:w-6/12 xl:w-4/12"
        v-if="!(group?.id && group?.info?.ads == false)"
      >
        <ErrorBox :errors="errors" class="mb-5" />
        <MnrSelect
          v-model="adType.value"
          :options="adType.options"
          oValue="id"
          oText="name"
          :dValue="0"
          :search="true"
          dText="سایر موارد"
          title="موضوع تبلیغات"
          placeholder="یک مورد را انتخاب کنید"
          hint="برای رفتن به مرحله بعد کلید زیر را بزنید"
          :msg="adType.value === null && 'ابتدا موضوع تبلیغات را انتخاب کنید'"
        ></MnrSelect>
        <Button
          @click="submit"
          :disabled="disabaledForm"
          v-show="adType.value !== null"
          class="bg-cyan-500 hover:bg-cyan-600 active:bg-cyan-700 text-cyan-50 mt-5"
        >
          مرحله بعد
        </Button>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import MnrSelect from "@/components/mnr/MnrSelect.vue";
import Button from "@/components/Button.vue";
import ErrorBox from "@/components/ErrorBox.vue";
import _ from "lodash";

export default {
  components: {
    MnrSelect,
    Button,
    BodySimple,
    ErrorBox,
  },
  data() {
    return {
      firstLoading: true,
      loadingGroup: true,
      disabaledForm: false,
      L_: _,
      group: {},
      adType: {
        value: null,
        options: [],
      },
    };
  },
  mounted() {
    this.getTypes();
    this.loadGroup();
  },
  methods: {
    getTypes() {
      let $this = this;
      $this.errors = {};
      $this.disabaledForm = true;

      $this.$axios
        .get("/api/ad-types")
        .then(function (response) {
          // console.log(response);
          $this.adType.options = response.data.adTypes;
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.firstLoading = false;
          $this.disabaledForm = false;
        });
    },
    loadGroup() {
      let $this = this;
      if ($this.$route?.query?.groupId == undefined) {
        $this.loadingGroup = false;
        return;
      }
      $this.errors = {};
      $this.disabaledForm = true;

      $this.$axios
        .get("/api/ad-targets-select/group", {
          params: {
            id: $this.$route.params.id,
            groupId: $this.$route?.query?.groupId,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.group = response.data.group;
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.firstLoading = false;
          $this.loadingGroup = false;
        });
    },
    submit() {
      let $this = this;
      $this.errors = {};
      $this.disabaledForm = true;

      $this.$axios
        .post("/api/ad-targets/", {
          id: $this.$store.state.user.id,
          adTypeId: $this.adType.value,
        })
        .then(function (response) {
          // console.log(response);
          if ($this.group?.id && $this.group?.info?.ads) {
            $this.$router.push({
              name: "TargetOrderInGroup",
              params: {
                id: $this.$store.state.user.id,
                adId: response.data.ad.id,
                groupId: $this.group?.id,
              },
            });
          } else {
            $this.$router.push({
              name: "TargetOrderSelect",
              params: { id: $this.$store.state.user.id, adId: response.data.ad.id },
            });
          }
        })
        .catch(function (error) {
          $this.errors = error.data;
          $this.disabaledForm = false;
        });
    },
  },
};
</script>
